import _typeof from "/data/www/wwwroot/jiankao/jiankao_admin_test/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.match";
var base = {
  // 对象深复制函数
  objClone: function objClone(obj) {
    var newObj = JSON.stringify(obj);
    newObj = JSON.parse(newObj);
    return newObj;
  },
  arrayObjClone: function arrayObjClone() {
    var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  } // return array.map(n => this.objClone(n))
  ,
  // 判断用户设备环境
  native: function native() {
    var u = navigator.userAgent;
    var ua = navigator.userAgent.toLocaleLowerCase();
    // var app = navigator.appVersion
    var result = {
      trident: u.indexOf('Trident') > -1,
      // IE内核
      presto: u.indexOf('Presto') > -1,
      // opera内核
      webKit: u.indexOf('AppleWebKit') > -1,
      // 苹果、谷歌内核
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,
      // 火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/),
      // 是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      // IOS终端
      android: u.indexOf('Android') > -1,
      // 安卓终端
      iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,
      // 是否为iphone或QQHD浏览器
      iPad: u.indexOf('iPad') > -1,
      // 是否为iPad
      webApp: u.indexOf('Safari') === -1,
      // 是否web应用程序，没有头部与底部
      QQbrw: u.indexOf('MQQBrowser') > -1,
      // QQ浏览器
      weiXin: u.indexOf('MicroMessenger') > -1,
      // 微信
      alipay: u.indexOf('AlipayClient') > -1,
      // 支付宝
      QQ: ua.match(/QQ/i) === 'qq',
      // QQ
      weiBo: ua.match(/WeiBo/i) === 'weibo',
      // 微博
      ucLowEnd: u.indexOf('UCWEB7.') > -1,
      //
      ucSpecial: u.indexOf('rv:1.2.3.4') > -1,
      webview: !(u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/)) && u.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
      ucweb: function () {
        try {
          return parseFloat(u.match(/ucweb\d+\.\d+/gi).toString().match(/\d+\.\d+/).toString()) >= 8.2;
        } catch (e) {
          if (u.indexOf('UC') > -1) {
            return true;
          }
          return false;
        }
      }(),
      Symbian: u.indexOf('Symbian') > -1,
      ucSB: u.indexOf('Firofox/1.') > -1
    };
    return result;
  },
  // 格式化金额
  formatMoney: function formatMoney(value) {
    var symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var bits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    value = value ? typeof value === 'number' ? value.toFixed(bits) : value : '0';
    // 判断是否为负数
    var isNegative = value[0] === '-';
    value = isNegative ? value.slice(1) : value;
    var tempArray = value.split('.');
    var bigNum = tempArray[0];
    var smallNum = tempArray[1] ? tempArray[1] : new Array(bits + 1).join('0');
    tempArray = [];
    for (var i = bigNum.length - 1; i >= 0; i -= 3) {
      if (i <= 2) {
        tempArray.unshift(bigNum.slice(0, i + 1));
      } else {
        tempArray.unshift(bigNum.slice(i - 2, i + 1));
      }
    }
    bigNum = tempArray.join(',');
    return isNegative ? "".concat(symbol, "-").concat(bigNum, ".").concat(smallNum) : "".concat(symbol).concat(bigNum, ".").concat(smallNum);
  },
  // 判断请求是否成功
  isSucc: function isSucc(res) {
    return res.status_code === 0;
  },
  // 时间格式化
  formatDate: function formatDate(fmt, date) {
    var o = {
      'M+': date.getMonth() + 1,
      // 月份
      'd+': date.getDate(),
      // 日
      'h+': date.getHours(),
      // 小时
      'm+': date.getMinutes(),
      // 分
      's+': date.getSeconds(),
      // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3),
      // 季度
      'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      }
    }
    return fmt;
  },
  // 将对象中的空字符串过滤掉
  transObjNull: function transObjNull(obj) {
    var res = {};
    Object.keys(obj).forEach(function (key) {
      if (!obj[key] && obj[key] !== 0) return;
      res[key] = obj[key];
    });
    return res;
  },
  // 判断是否为空的函数
  // 返回true则不为空，返回false为空
  isNotNull: function isNotNull(item) {
    // 先判断是否为对象
    if (_typeof(item) === 'object') {
      if (item.constructor === Array) return item.length > 0;else return JSON.stringify(item) !== '{}';
    }
    return !!item;
  },
  // 空数组转换为空对象
  transArrToObj: function transArrToObj(arr) {
    // 判断是否为空数组
    if (arr && arr.constructor === Array && arr.length === 0) return {};
    return arr;
  },
  /*
  * 格式化普通资源的选项，改为前端通用格式
  * :params items {Array} 选项的数组，格式为{id: Number, name: String}
  * :params rawFormat {Array} 数组的原格式，默认为name和id
  * :params targetFormat {Array} 数组的目标格式，默认为label和value
  * ！！注意：rawFormat和targetFormat键值对的位置要对应！！
  * :return result {Array} 格式化后的通用格式数组，格式为{value: Number, label: String}
  */
  formatItems: function formatItems(items) {
    var rawFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ['name', 'id'];
    var targetFormat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ['label', 'value'];
    return items.map(function (item) {
      var res = {};
      rawFormat.forEach(function (f, index) {
        res[targetFormat[index]] = item[f];
      });
      return res;
    });
  }
};
var exportValue = {};
exportValue.install = function (Vue) {
  for (var key in base) {
    if (base.hasOwnProperty(key)) {
      Vue.prototype["$".concat(key)] = base[key];
    }
  }
};
for (var key in base) {
  if (base.hasOwnProperty(key)) {
    exportValue[key] = base[key];
  }
}
export default exportValue;