import "/data/www/wwwroot/jiankao/jiankao_admin_test/node_modules/core-js/modules/es6.array.iterator.js";
import "/data/www/wwwroot/jiankao/jiankao_admin_test/node_modules/core-js/modules/es6.promise.js";
import "/data/www/wwwroot/jiankao/jiankao_admin_test/node_modules/core-js/modules/es6.object.assign.js";
import "/data/www/wwwroot/jiankao/jiankao_admin_test/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';

// Css重置方法（解决不同浏览器的默认样式的差异）
import 'normalize.css/normalize.css';

// 全局引用 element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN';

// 全局样式
import '@/styles/index.scss';

// 引入通用组件
import '@/components/common';
import App from "./App";
import store from "./store";
import router from "./router";

// base
import base from '@/utils/base';

// api
import api from '@/api';
Vue.prototype.api = api;
import '@/icons'; // icon
import '@/permission'; // permission control

Vue.use(ElementUI, {
  locale: locale
});
Vue.use(base);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});